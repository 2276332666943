<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Text Field</h2>
    </template>
    <template #page-content>
      <div>
        <div class="r-card">
          <h3>Overview</h3>
          <p>
            Teks Field memberi orang cara untuk memasukkan dan mengedit teks.
            Mereka digunakan dalam formulir, dialog modal, tabel, dan permukaan
            lain yang memerlukan input teks.
          </p>
        </div>
        <r-card>
          <div class="level">
            <h2>Default</h2>
          </div>
          <div class="columns is-multiline">
            <div class="column is-full">
              <h4>Basic Text Field</h4>
              <b-field label="Standard">
                <b-input
                  icon="pencil"
                  placeholder="Normal with hint text"
                  maxlength="30"
                ></b-input>
              </b-field>
              <b-field
                label="Standard With Error"
                type="is-danger"
                message="This email is invalid"
              >
                <b-input type="email" value="john@" maxlength="30"></b-input>
              </b-field>
              <b-field label="Standard With Icon">
                <b-input icon-right="menu" maxlength="30"></b-input>
              </b-field>
              <b-field label="Read Only">
                <b-input readonly value="this is read only"></b-input>
              </b-field>
            </div>
            <div class="column is-full">
              <h4>Text Area</h4>
              <b-input
                maxlength="200"
                type="textarea"
                value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consequat, sapien ut feugiat posuere, nulla libero elementum diam, in porttitor nisl nibh ac nibh. Nam id magna interdum, hendrerit tellus vitae, lobortis ex."
              ></b-input>
            </div>
          </div>
        </r-card>
        <r-card>
          <div class="level">
            <h2>Underlined</h2>
          </div>
          <div class="columns is-multiline">
            <div class="column is-full">
              <h4>Basic Text Field</h4>
              <b-field label="Standard">
                <b-input
                  class="is-underlined"
                  placeholder="Normal with hint text"
                  maxlength="30"
                ></b-input>
              </b-field>
              <b-field
                label="Standard With Error"
                type="is-danger"
                message="This email is invalid"
              >
                <b-input
                  type="email"
                  class="is-underlined"
                  value="john@"
                  maxlength="30"
                ></b-input>
              </b-field>
              <b-field label="Standard With Icon">
                <b-input
                  class="is-underlined"
                  icon-right="menu"
                  maxlength="30"
                ></b-input>
              </b-field>
              <b-field label="Read Only">
                <b-input
                  class="is-underlined"
                  readonly
                  value="this is read only"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-full">
              <h4>Text Area</h4>
              <b-input
                maxlength="200"
                class="is-underlined"
                type="textarea"
                value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consequat, sapien ut feugiat posuere, nulla libero elementum diam, in porttitor nisl nibh ac nibh. Nam id magna interdum, hendrerit tellus vitae, lobortis ex."
              ></b-input>
            </div>
          </div>
        </r-card>
        <r-card>
          <div class="level">
            <h2>Datepicker</h2>
          </div>
          <div class="columns is-multiline">
            <div class="column">
              <b-datepicker v-model="datepicker"></b-datepicker>
            </div>
            <div class="column">
              <b-datepicker
                placeholder="Type or select a date..."
                icon="calendar-today"
                editable
              ></b-datepicker>
            </div>
          </div>
        </r-card>
      </div>
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      datepicker: new Date(),
      focusedDate: new Date(),
      activeTab: 2,
      countTab: 1,
    }
  },
  mounted() {
    // console.log('matched', this.$rVar)
  },
}
</script>
